import { useMemo } from "react";
import { Article, Articles } from "../types/article";

export const useUniqueArticles = (articles: Articles) => {
  const uniqueArticles = useMemo(() => {
    const articlesProductIdMap = new Map<string, Article>();
    for (const article of articles) {
      const oldArticle = articlesProductIdMap.get(article.productArticleId);
      if (oldArticle) {
        oldArticle.nbrArticles =
          (oldArticle?.nbrArticles ?? 1) + article.nbrArticles;
      } else {
        articlesProductIdMap.set(article.productArticleId, { ...article });
      }
    }
    return Array.from(articlesProductIdMap.values());
  }, [articles]);
  return uniqueArticles.sort(({ itemName: name1 }, { itemName: name2 }) =>
    name1 < name2 ? -1 : 1
  );
};
