import React from "react";
import { useTranslation } from "@coworker/locales";
import { Text } from "./Text";
import { getRoomsCountTranslation } from "../tools";
import { PaddedContainer } from "../styles/styles";

interface RoomsCountTextProps {
  roomSettings: number;
  searchCount: number;
}

export function RoomsCountText({
  roomSettings,
  searchCount,
}: RoomsCountTextProps) {
  const { t } = useTranslation();

  const settingsOrSetting = t(getRoomsCountTranslation(roomSettings));
  const searchCounter =
    roomSettings === searchCount
      ? roomSettings
      : `${searchCount}/${roomSettings}`;

  return (
    <PaddedContainer $py="8px" $px="24px">
      <Text
        text={`${t("viewingString")} ${searchCounter} ${settingsOrSetting}`}
        grey
      />
    </PaddedContainer>
  );
}
