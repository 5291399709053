import React, { useState } from "react";
import ListView from "@ingka/list-view";
import { useTranslation } from "@coworker/locales";
import { useInputPopup } from "../../InputPopup";
import { useWorkspacesAction } from "../../../hooks/useWorkspaces";
import { useTasksByStore } from "../hooks/useTasksByStore";
import useFlag, { FLAGS } from "../../../hooks/useFlag";
import { useStoreId } from "../../../core/auth/useLoggedInUser";
import { RightArrow } from "../Atoms/Icons/RightArrow";
import { RoomListItem } from "../Molecules/RoomListItem";
import { TwoLinedGreyBox } from "../Molecules/TwoLinedGreyBox";
import { RoomsSearchBar } from "../Molecules/RoomsSearchBar";
import { RoomsViewSkeleton } from "../Skeletons/RoomsViewSkeleton";
import { TitleHeader } from "../../TestBuy/CommonComponents/TitleHeader/TitleHeader";
import { Rooms } from "../types/room";
import { useAlertsByStore } from "../hooks/useAlertsByStore";
import { RoomsCountText } from "../Atoms/RoomsAmountText";
import { useRoomsByStoreId } from "../hooks/useRoomsByStoreId";
import { NoRoomsLaunchAdmin } from "../Molecules/NoRoomsLaunchAdmin";
import { useActiveRoomsByStoreId } from "../hooks/useActiveRoomsByStoreId";
import { FixaWindow } from "@coworker/reusable";
import { RoomsViewCombinedSearch } from "./RoomsViewCombinedSearch";

function RoomsViewOld() {
  const { t } = useTranslation();
  const { push } = useWorkspacesAction();
  const { getInput } = useInputPopup();
  const storeId = useStoreId();
  const { data: rooms, isLoading: isRoomsLoading } = useRoomsByStoreId(storeId);
  const { activeRooms, isLoading: isActiveRoomsLoading } =
    useActiveRoomsByStoreId(storeId);
  const { data: alerts } = useAlertsByStore(storeId);

  const tasks = useTasksByStore(storeId);
  const hideForGermanyUsers = useFlag(FLAGS.GERMANY_ROLLOUT);

  const [searchResult, setSearchResult] = useState<Rooms>([]);

  const handleSearchResults = (results: Rooms) => {
    setSearchResult(results);
  };

  const handleSearch = async () => {
    const searchResult = await getInput("scansearch", {
      start: new Date(),
      withHistory: false,
    });

    if (searchResult?.product) {
      push(`/roomsettings/articles/search/${searchResult.product}`);
    }
  };

  React.useEffect(() => {
    if (activeRooms) {
      setSearchResult(activeRooms);
    }
  }, [activeRooms]);

  if (hideForGermanyUsers) {
    return (
      <>
        <TitleHeader title={t("roomSettingsString")} />
        <RoomsViewSkeleton />
      </>
    );
  }

  if (isRoomsLoading || isActiveRoomsLoading) {
    return (
      <>
        <TitleHeader
          title={t("roomSettingsString")}
          showSearch={true}
          onSearch={handleSearch}
          onBack={() => {
            push("/");
          }}
        />

        <RoomsViewSkeleton />
      </>
    );
  }

  // No active room
  if ((rooms?.length ?? 0) > 0 && (activeRooms?.length ?? 0) === 0) {
    return (
      <NoRoomsLaunchAdmin keyForMessageToUser={"allRoomsInactiveString"} />
    );
  }

  // No rooms
  if ((rooms?.length ?? 0) === 0) {
    return <NoRoomsLaunchAdmin keyForMessageToUser={"noRoomsCreatedString"} />;
  }

  return (
    <>
      <TitleHeader
        title={t("roomSettingsString")}
        showSearch
        onSearch={handleSearch}
        onBack={() => {
          push("/");
        }}
      />

      <TwoLinedGreyBox
        headLine={t("ongoingTasksString")}
        bottomleftText={`${tasks.count}`}
        rightComponent={<RightArrow />}
        onClick={() => {
          push(`/roomsettings/tasks`);
        }}
        tabIndex={0}
        role="button"
        ariaLabel={t("ongoingTasksAriaLabelString", { count: tasks.count })}
      />

      <RoomsSearchBar
        rooms={activeRooms ?? []}
        onSearch={handleSearchResults}
      />
      <RoomsCountText
        roomSettings={activeRooms?.length || 0}
        searchCount={searchResult.length}
      />
      <ListView id="rooms-list">
        {searchResult &&
          searchResult
            .sort((a, b) => a.name.localeCompare(b.name))

            .map((room) => (
              <RoomListItem
                key={room.id}
                room={room}
                taskLinks={tasks.taskLinks}
                alerts={alerts || []}
              />
            ))}
      </ListView>
    </>
  );
}

export function RoomsView() {
  if ((window as FixaWindow).showRoomsCombinedSearch) {
    return <RoomsViewCombinedSearch />;
  } else {
    return <RoomsViewOld />;
  }
}
