import React from "react";
import { Trans } from "@coworker/locales";
import { DetailSection, DetailSectionLeft, SectionTitle } from "./_common";
import { useWorkspacesAction } from "../../../hooks/useWorkspaces";
import { SimpleProductInfo } from "@coworker/components";
import { formatFullIdWithDots } from "@coworker/reusable";
import { useFetchedBasicProduct } from "../../../hooks/useBasicProductsMap";

function SimpleProductRow({ task }) {
  const { push } = useWorkspacesAction();
  const full_id = `${task.product_article_type}${task.product_article_id}`;
  const { basicProduct } = useFetchedBasicProduct(full_id);
  const description = basicProduct.descriptivesWithoutKindAndColor;

  return (
    <DetailSection
      data-testid="productSection"
      onClick={() => {
        push(`/product/${full_id}`);
      }}
    >
      <DetailSectionLeft>
        <SectionTitle>
          <Trans>product2String</Trans>
        </SectionTitle>
        <SimpleProductInfo
          title={basicProduct.name}
          description={description}
          formattedNumber={formatFullIdWithDots(full_id)}
          withArrow
        />
      </DetailSectionLeft>
    </DetailSection>
  );
}

export default SimpleProductRow;
