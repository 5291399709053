import { useMemo } from "react";
import { Article, Articles } from "../types/article";
import { Group, Groups } from "../types/groups";

export const useGroupAndArticleCount = (
  article: Article,
  articles: Articles,
  groups: Groups
) => {
  const groupsAndCounts = useMemo(() => {
    const articlesWithSameArticleId = articles.filter(
      ({ productArticleId }) => productArticleId === article.productArticleId
    );
    const groupsAndCountsMap = new Map<
      string,
      { group: Group | undefined; nbrArticles: number }
    >();
    for (const articleWithSameArticleId of articlesWithSameArticleId) {
      const groupId = articleWithSameArticleId?.groupId ?? "";
      let group;
      if (groupId) {
        group = groups.find(({ id }) => groupId === id);
      }
      const groupAndCount = groupsAndCountsMap.get(groupId);
      if (groupAndCount) {
        groupAndCount.nbrArticles += articleWithSameArticleId.nbrArticles;
      } else {
        groupsAndCountsMap.set(groupId, {
          group,
          nbrArticles: articleWithSameArticleId.nbrArticles,
        });
      }
    }
    return Array.from(groupsAndCountsMap.values()).sort(
      ({ group: group1 }, { group: group2 }) => {
        if (!group1) {
          return -1;
        } else if (!group2) {
          return 1;
        } else {
          return group1.name < group2.name ? -1 : 1;
        }
      }
    );
  }, [article, articles, groups]);
  return groupsAndCounts;
};
