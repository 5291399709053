import React, { useState } from "react";
import { useTranslation } from "@coworker/locales";
import { SearchBarContainer } from "../styles/styles";
import Search from "@ingka/search";

interface RoomsSearchBarCombinedSearchProps {
  onSearch: (query: string | undefined) => void;
}

export const RoomsSearchBarCombinedSearch = ({
  onSearch,
}: RoomsSearchBarCombinedSearchProps) => {
  const [searchInput, setSearchInput] = useState("");
  const { t } = useTranslation();

  const handleSearch = (e: InputEvent) => {
    const searchInput = (e.target as HTMLInputElement).value;

    setSearchInput((e.target as HTMLInputElement).value);

    // Require two characters before trigger search
    if (searchInput.length > 1) {
      onSearch(searchInput);
    } else {
      onSearch(undefined);
    }
  };

  const handleClear = () => {
    onSearch(undefined);
  };

  return (
    <SearchBarContainer>
      <Search
        id="room-search"
        value={searchInput}
        onChange={(e: InputEvent) => {
          handleSearch(e);
        }}
        onClear={handleClear}
        placeholder={t("searchBasicString")}
        ariaLabel={t("roomsViewSearch")}
      />
    </SearchBarContainer>
  );
};
