import React from "react";
import { Articles } from "../types/article";
import { Room } from "../types/room";
import { ArticleGroupItem } from "../Molecules/ArticleGroupItem";
import { ListWrapper } from "../styles/styles";
import { Groups } from "../types/groups";

interface GroupedArticleListingProps {
  articles: Articles;
  room: Room;
  groups?: Groups | undefined;
}

export const GroupedArticleListing = ({
  articles,
  room,
  groups,
}: GroupedArticleListingProps) => {
  console.log("GroupedArticleListingProps", { articles, room });
  if (!groups) {
    return <></>;
  } else {
    console.log("Groups", groups);

    return (
      <ListWrapper>
        {groups.map((group, index) => (
          <ArticleGroupItem
            key={`${room.id}-${group.id}-${index}`}
            group={group}
            articles={articles}
          />
        ))}
      </ListWrapper>
    );
  }
};
