import React from "react";
import styles from "./task-card.module.css";
import { useMultiSelectForTab } from "@coworker/app/src/hooks/useMultiSelect";

interface MultiSelectProps {
  multiSelect: boolean;
  type: string;
  taskId: string;
}

export const MultiSelect = ({
  multiSelect,
  type,
  taskId,
}: MultiSelectProps) => {
  const { checkIfSelected, toggle } = useMultiSelectForTab(type) as any;
  const toggleThis = () => toggle(taskId);
  const isSelected = checkIfSelected?.(taskId);
  return (
    <>
      {multiSelect && (
        <div className={styles["checkbox-wrapper"]}>
          <input
            type="checkbox"
            className={styles["checkbox"]}
            checked={isSelected}
            onChange={toggleThis}
          />
        </div>
      )}
    </>
  );
};
