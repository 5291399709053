import React from "react";
import styles from "./task-card.module.css";
import { useWorkspacesAction } from "@coworker/app/src/hooks/useWorkspaces";
import { ModalContext } from "../../../layout/context/ModalContextProvider";
import { ModalType } from "../../../layout/context/modalType";
import trackerHelper from "@coworker/app/src/helpers/tracker";

interface LayoutProps {
  multiSelect: boolean;
  isSwiping: boolean;
  taskId: string;
  taskModal: boolean;
  children: React.ReactNode;
}

export const Layout = ({
  multiSelect,
  isSwiping,
  taskId,
  taskModal,
  children,
}: LayoutProps) => {
  const { push } = useWorkspacesAction();
  const { toggleModal } = React.useContext(ModalContext);

  return (
    <div
      className={`${styles["task-card"]} ${
        multiSelect && styles["multi-select"]
      }`}
      onClick={() => {
        if (taskModal) {
          toggleModal({
            type: ModalType.TaskDetail,
            payload: { taskId: taskId },
          });
          trackerHelper.trackActivityTaskCardClick();
        } else if (!isSwiping) {
          push(`/task/${taskId}`);
        } else {
          return;
        }
      }}
    >
      {children}
    </div>
  );
};
