import React from "react";
import styles from "./task-card.module.css";
import { getTaskInfo } from "../../helpers/getTaskInfo";
import { useTranslation } from "@coworker/locales";
import { useFetchedBasicProduct } from "@coworker/app/src/hooks/useBasicProductsMap";
import { Task } from "@coworker/types/lib/tasks/base";
import { AddonTask } from "@coworker/types/lib/tasks/addon";
import useFormatter from "@coworker/app/src/hooks/useFormatter";
import { CardMode } from "./types";
import { Creator } from "./Creator";
import { CreatedAtDate } from "./CreatedAtDate";
import { MultiSelect } from "./MultiSelect";
import { Type } from "./Type";
import { Priority } from "./Priority";
import { ImagesAndComments } from "./ImagesAndComments";
import { Image } from "./Image";
import { Titles } from "./Titles";
import { Frequency } from "./Frequency";
import { AddonSubtitle } from "./AddonSubtitle";
import { Assignee } from "./Assignee";
import { ClosedBy } from "./ClosedBy";
import { CARD_MODE } from "./constants";
import { Layout } from "./Layout";
import taskStateOptions from "@coworker/functions/src/enums/taskState.json";

interface TaskCardProps {
  task: Task;
  multiSelect?: boolean;
  isSwiping?: boolean;
  type?: string;
  taskModal?: boolean;
  cardMode?: CardMode;
}

export const TaskCard = ({
  task,
  multiSelect,
  isSwiping,
  type,
  taskModal = false,
  cardMode = CARD_MODE.DEFAULT,
}: TaskCardProps) => {
  const { t } = useTranslation();

  const addonTask = task as AddonTask;
  const { productUnit } = useFormatter();
  const productUnitTitle = productUnit(
    addonTask.requested_type,
    addonTask.actual_requested_quantity
  );
  const taskInfo = getTaskInfo(
    task,
    t as (key?: string) => string,
    productUnitTitle
  );

  const fullId = `${addonTask.product_article_type}${addonTask.product_article_id}`;
  const { basicProduct } = useFetchedBasicProduct(fullId);
  const productDescripion =
    basicProduct?.descriptivesWithoutKindAndColor || basicProduct?.descriptives;

  const isTaskClosed = task.state === taskStateOptions.CLOSED;

  if (!task) return null;

  return (
    <>
      <MultiSelect
        multiSelect={multiSelect!!}
        type={type ?? ""}
        taskId={task.id ?? ""}
      />
      <Layout
        taskId={task.id ?? ""}
        multiSelect={multiSelect!!}
        isSwiping={isSwiping!!}
        taskModal={taskModal}
      >
        <div className={styles["top-wrapper"]}>
          <Type task={task} type={taskInfo.type ?? ""} />
          <Priority task={task} state={task.state} cardMode={cardMode} />
          <ImagesAndComments taskId={task.id ?? ""} />
        </div>
        <div className={styles["middle-wrapper"]}>
          <Image image={basicProduct.smallImage} cardMode={cardMode} />
          <div className={styles["title-wrapper"]}>
            <Titles
              title={taskInfo.title ?? ""}
              subtitle={taskInfo.subtitle ?? ""}
              cardMode={cardMode}
              type={task.task_type}
              productDescripion={productDescripion}
            />
            <Frequency task={task} cardMode={cardMode} state={task.state} />
          </div>
        </div>
        <AddonSubtitle
          cardMode={cardMode}
          type={task.task_type}
          subtitle={taskInfo.subtitle ?? ""}
        />
        {cardMode === CARD_MODE.COMPLEX && !isTaskClosed && (
          <div className={styles["creator-created-at-wrapper"]}>
            <Creator creatorId={task.creator_id} />
            <CreatedAtDate createdAt={task.created_at} />
          </div>
        )}
        <Assignee state={task.state} assignedTeamId={task.assigned_team_id} />
        <ClosedBy
          assignedTeamId={task.assigned_team_id}
          closeType={task.close_type as string}
          state={task.state}
        />
      </Layout>
    </>
  );
};
