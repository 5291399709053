import { getTokenAsync } from "./getAuthToken";
import packageJson from "../../../package.json";

export const makeHeaders = (token: string) => ({
  Authorization: `Bearer ${token}`,
  "Content-Type": "application/json",
  "X-Client-Version": packageJson.version,
});

export async function callToFetch(method: string, url: string, headers = {}) {
  try {
    const token = await getTokenAsync(true);
    const params = {
      method,
      headers: { ...makeHeaders(token), ...headers },
    };
    const response = await fetch(url, params);
    const contentType = response.headers.get("Content-Type");
    if (!response.ok) {
      let errorDetails;
      if (contentType && contentType.includes("application/json")) {
        errorDetails = await response.json();
      } else {
        errorDetails = await response.text();
      }
      throw new Error(
        `Fetching from core-service failed: ${response.status} ${response.statusText}. Details: ${errorDetails}`
      );
    }

    if (contentType && contentType.includes("application/json")) {
      return response.json();
    } else {
      return response.text();
    }
  } catch (error: any) {
    console.error(`Error in callToFetch: ${error.message}`);
    throw new Error(`Error in callToFetch: ${error.message}`);
  }
}
