import React from "react";
import styled from "styled-components";
import { PositiveNumberKeypad } from "../../PositiveNumberKeypad";
import { OverflowBackground } from "../../InputPopup/CommonComponents";
import { SkapaModalHeader } from "../../SkapaModalHeader";
import SkapaInputField from "../../InputPopup/shared/SkapaInputField";
import { cleanKeyNumberPadValue } from "../helper";

interface KeyNumberPadProps {
  value: string;
  setValue: React.Dispatch<React.SetStateAction<string>>;
  setShowKeyNumberPad: React.Dispatch<React.SetStateAction<boolean>>;
  showComma?: boolean;
  title: string;
  currency?: string;
}

const Container = styled.div`
  width: 100%;
  position: fixed;
  left: 0;
  bottom: 0;
  background: var(--white);
  border-top: 2px solid var(--grey200);
  z-index: 100;
  height: 55%;
`;

const StyledOverflowBackground = styled(OverflowBackground)`
  height: 45%;
`;

const InputWrapper = styled.div`
  width: 80%;
  margin: 0px auto;
`;

const KeyPadWrapper = styled.div`
  border-top: 1px solid var(--grey200);
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 10px;
`;

export const KeyNumberPad = ({
  value,
  setValue,
  setShowKeyNumberPad,
  showComma = false,
  title,
  currency = "",
}: KeyNumberPadProps) => {
  return (
    <Container>
      <StyledOverflowBackground
        onClick={() => {
          setShowKeyNumberPad(false);
        }}
      />
      <SkapaModalHeader
        titleString={title}
        onClose={() => setShowKeyNumberPad(false)}
        useSeparator={false}
      />
      <InputWrapper>
        <SkapaInputField
          inputFieldProps={{
            value: value,
            readOnly: true,
            suffixLabel: currency,
          }}
          formFieldProps={{
            fieldHelper: {
              msg: "",
            },
          }}
        />
      </InputWrapper>
      <KeyPadWrapper>
        <PositiveNumberKeypad
          disabled={!value}
          initialValue={cleanKeyNumberPadValue(value)}
          onUpdate={setValue}
          onComplete={() => setShowKeyNumberPad(false)}
          showComma={showComma}
        />
      </KeyPadWrapper>
    </Container>
  );
};
