import React from "react";
import { useTranslation } from "@coworker/locales";
import { Article, Articles } from "../../types/article";
import { Group, Groups } from "../../types/groups";
import { useQueryClient } from "@tanstack/react-query";
import { updateArticle } from "../../services/articles.service";
import Modal, { ModalBody, ModalHeader, Sheets } from "@ingka/modal";
import styled from "styled-components";
import { Text } from "../../Atoms/Text";
import { ListImage } from "../../Atoms/ListImage";
import { ArticleIdentifier } from "../../Atoms/ArticleIdentifier";
import QuantityStepper from "@ingka/quantity-stepper";
import { formatFullIdWithDots } from "@coworker/reusable";
import { useGroupAndArticleCount } from "../../hooks/useGroupAndArticleCount";

const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  padding: 10px;
`;
const RowContainerBorderBottom = styled(RowContainer)`
  border-bottom: 1px solid var(--grey200);
`;
const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
`;
const ColumnContainer = styled.div<{ $centerText: boolean }>`
  flex-grow: 1;
  flex-direction: column;
`;

interface EditGroupQuantityModalProps {
  article: Article;
  articles: Articles;
  groups: Groups;
  onClose: () => void;
}

export const EditGroupQuantityModal = ({
  article,
  articles,
  groups,
  onClose,
}: EditGroupQuantityModalProps) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const groupsAndCounts = useGroupAndArticleCount(article, articles, groups);

  const handleQuantityChange = async (
    group: Group | undefined,
    nbrArticles: number
  ) => {
    try {
      const currentArticle = articles.find(
        ({ productArticleId, groupId }) =>
          productArticleId === article.productArticleId &&
          (group ? groupId === group.id : !groupId)
      );
      await updateArticle(currentArticle!.id, {
        ...currentArticle,
        nbrArticles,
      });
      await queryClient.invalidateQueries(["articlesByRoom"]);
    } catch (_error) {
      alert(t("backendOperationFailedString"));
    }
  };

  return (
    <Modal
      handleCloseBtn={() => {
        onClose();
      }}
      visible={true}
    >
      <Sheets
        alignment="right"
        aria-label="Accessibility header for a modal"
        footer={null}
        header={
          <ModalHeader
            ariaCloseTxt="Close edit quantity"
            title={t("editQuantityString")}
            closeBtnClick={() => {
              onClose();
            }}
            style={{ borderBottom: "1px solid var(--grey200)" }}
          />
        }
        size="small"
      >
        <ModalBody style={{ padding: "0px" }}>
          <RowContainer>
            <LeftContainer>
              <ListImage src={article.imageSmall} alt={""} />
            </LeftContainer>
            <ColumnContainer $centerText={false}>
              <Text text={article.itemName} bold />
              <ArticleIdentifier
                articleId={formatFullIdWithDots(article.productArticleId)}
              />
              <Text text={article.itemType} grey />
            </ColumnContainer>
          </RowContainer>
          {groupsAndCounts.map(({ group, nbrArticles }, index) => (
            <RowContainerBorderBottom key={index}>
              <LeftContainer>
                {group !== undefined && <Text text={"Group"} bold />}
                <Text text={group?.name ?? t("notPartOfGroupString")} />
              </LeftContainer>
              <QuantityStepper
                small={true}
                ariaDescribedBy="Type in a number or use arrow up or arrow down to change the quantity"
                ariaDescribedById="helper"
                ariaLabelDecrease="Decrease value"
                ariaLabelIncrease="Increase value"
                ariaLabelInput="Enter quantity"
                defaultValue={nbrArticles}
                maxVal={99}
                minVal={1}
                onQuantityChange={(changedNbrArticles) =>
                  changedNbrArticles !== nbrArticles &&
                  handleQuantityChange(group, changedNbrArticles)
                }
              />
            </RowContainerBorderBottom>
          ))}
        </ModalBody>
      </Sheets>
    </Modal>
  );
};
