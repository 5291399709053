import React from "react";
import Button from "@ingka/button";
import { Trans } from "@coworker/locales";
import { getActiveTask } from "./QuestionsDetailPage.helper";
import { useWorkspacesAction } from "../../../hooks/useWorkspaces";
import { Question } from "../BackendApiRepository/QuestionResponse";
interface MfaqTaskButtonProps {
  question: Question;
  filters: any; //TODO: Find out if we have type for this
}

const MfaqTaskButtonComponent: React.FC<MfaqTaskButtonProps> = ({
  question,
  filters,
}) => {
  const { push } = useWorkspacesAction();
  const { hasOngoingTask, taskId } = getActiveTask(question, filters[1]);
  const taskString = hasOngoingTask ? "showTaskString" : "createTaskString";
  const createMFAQTask = () => {
    push(`/task/new/mfaq_followup`, {
      question: { ...question, question_current_filters: filters },
    });
  };
  const showMFAQTask = () => {
    push(`/task/${taskId}`);
  };
  return (
    <Button
      data-testid="createMFAQTask"
      type="primary"
      text={<Trans>{taskString}</Trans>}
      onClick={taskId ? showMFAQTask : createMFAQTask}
    />
  );
};

export default MfaqTaskButtonComponent;
