import React from "react";
import { useParams } from "react-router";
import { useRoomById } from "../hooks/useRoomById";
import { RoomArticles } from "../Molecules/RoomArticles";
import { RoomImagesCarousel } from "../Molecules/RoomImagesCarousel";
import { FullWidthButton } from "../../SkapaButton";
import { useTranslation } from "@coworker/locales";

import { SkapaToast } from "../Atoms/SkapaToast";
import { useActiveArticlesByRoom } from "../hooks/useActiveArticlesByStore";
import { RoomViewSkeleton } from "../Skeletons/RoomViewSkeleton";
import { useImagesByRoomId } from "../hooks/useImagesByRoomId";
import { ArticleFilters } from "../Molecules/ArticleFilters";
import {
  fullRoomName,
  joinProductArticleIds,
  sortAndFilterArticles,
} from "../tools";
import { useTasksByRoom } from "../hooks/useTasksByRoom";
import { useEDSData } from "../hooks/useEDSData";
import { NoDataMessage } from "../Atoms/NoDataMessage";
import { ArticleFilterTypes, ArticleSortingTypes } from "../constants";
import { useSalesStopData } from "../hooks/useSalesStopData";
import preferences from "@coworker/functions/src/enums/profilePreferences.json";
import { useUserPreference } from "../../../hooks/useProfilePreferencesQuery";
import Image from "@ingka/image";
import { UploadImageButton } from "../Molecules/UploadImageButton";
import styled from "styled-components";
import FullScreenPopup from "@coworker/apprestructured/src/layout/components/FullScreenPopup/FullScreenPopup";
import { AppBarAction } from "@coworker/apprestructured/src/layout/components/AppBar/AppBar";
import SSRIcon from "@ingka/ssr-icon";
import SettingsIcon from "@ingka/ssr-icon/paths/gear";
import { useWorkspacesAction } from "../../../hooks/useWorkspaces";
import { useCountryId } from "../../../core/auth/useLoggedInUser";
import { Article, ArticleIdNbrArticles } from "../types/article";
import { ModalTypes } from "../types/views";
import { RoomArticlesModals } from "../Organisms/Modals/RoomArticlesModals";
import { PleaseFillIn } from "../../Reusable/PleaseFillIn";
import { useGroupsByRoomId } from "../hooks/useGroupsByRoomId";
import { Group } from "../types/groups";

const SVGResizer = styled.div`
  .image__icon {
    width: 140px;
    height: 140px;
  }
`;

export function RoomView() {
  const { roomId } = useParams();
  const { t } = useTranslation();

  const [toastVisible, setToastVisible] = React.useState(false);
  const [toastText] = React.useState("");
  const [isSelectArticlesMode, setIsSelectArticlesMode] = React.useState(false);
  const [selectedArticles, setSelectedArticles] = React.useState<Article[]>([]);
  const [warningText, setWarningText] = React.useState("");
  const [currentGroup, setCurrentGroup] = React.useState<Group>();

  const [modalVisibleByType, setModalVisibleByType] =
    React.useState<ModalTypes>(ModalTypes.DEFAULT_VIEW);
  const { push } = useWorkspacesAction();
  const countryId = useCountryId();

  const { activeArticles, isLoading: articlesLoading } =
    useActiveArticlesByRoom(roomId || "");
  const { data: room, isLoading: roomLoading } = useRoomById(roomId || "");
  const { data: roomImages } = useImagesByRoomId(roomId || "");
  const { taskLinks } = useTasksByRoom(roomId);

  const articleNumbers = joinProductArticleIds(activeArticles ?? []);
  const { data: edsData } = useEDSData(countryId, articleNumbers);
  const { data: salesStopData } = useSalesStopData(
    room?.store_id ?? "",
    articleNumbers
  );

  const { data: groups, isLoading: groupsLoading } = useGroupsByRoomId(
    roomId ?? ""
  );

  const [sortedAndFilteredArticles, setSortedAndFilteredArticles] =
    React.useState(activeArticles);

  // User preferences
  const [filter] = useUserPreference(
    preferences.RS_ARTICLE_FILTER,
    ArticleFilterTypes.ALL
  );
  const [sort] = useUserPreference(
    preferences.RS_ARTICLE_SORT_FILTER,
    ArticleSortingTypes.PRIORITY
  );
  const [hfb] = useUserPreference(preferences.RS_ARTICLE_HFB_FILTER, "all");
  let fetchSelectedArticleIdsFunction: () => ArticleIdNbrArticles[] = () => [];

  // Initial sorting and filtering
  React.useEffect(() => {
    if (!room || !activeArticles) return;
    const sortedArticles = sortAndFilterArticles(
      activeArticles,
      filter ?? ArticleFilterTypes.ALL,
      sort ?? ArticleSortingTypes.PRIORITY,
      hfb ?? "all",
      taskLinks,
      edsData ?? [],
      salesStopData ?? [],
      room
    );

    // Prevent infinite loop, if the articles are the same, do not update the state
    if (
      JSON.stringify(sortedArticles) ===
      JSON.stringify(sortedAndFilteredArticles)
    ) {
      return;
    }

    setSortedAndFilteredArticles(sortedArticles);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    activeArticles,
    edsData,
    room,
    salesStopData,
    taskLinks,
    filter,
    sort,
    hfb,
  ]);

  const handleSortedArticles = (
    selectedFilter: string,
    selectedSort: string,
    selectedHFB: string
  ) => {
    if (!room || !activeArticles) return;

    const initialArticles = [...activeArticles];
    const sortedArticles = sortAndFilterArticles(
      initialArticles,
      selectedFilter as ArticleFilterTypes,
      selectedSort as ArticleSortingTypes,
      selectedHFB,
      taskLinks,
      edsData ?? [],
      salesStopData ?? [],
      room
    );

    setSortedAndFilteredArticles(sortedArticles);
  };

  const roomHeader = fullRoomName(
    room?.name,
    room?.main_article_name_1,
    room?.main_article_name_2
  );

  const appBarActions: AppBarAction[] = [
    {
      name: "settings",
      icon: <SSRIcon paths={SettingsIcon} />,
      onClick: () => push(`/roomsettings/${room?.id}/settings`),
      position: "right",
    },
  ];

  if (articlesLoading || roomLoading || groupsLoading)
    return (
      <FullScreenPopup
        appBarConfig={{
          title: roomHeader,
          actions: appBarActions,
          onBack: () => {
            push("/roomsettings");
          },
        }}
        noPadding
      >
        <RoomViewSkeleton />
      </FullScreenPopup>
    );

  const selectedArticleIdsToArticles = (
    selectedArticleIdsNbArticles: ArticleIdNbrArticles[]
  ) => {
    return !activeArticles || !selectedArticleIdsNbArticles
      ? []
      : (selectedArticleIdsNbArticles
          .map((item) => {
            const foundArticle = activeArticles.find(
              (article) => article.id === item.articleId
            );
            if (foundArticle) foundArticle.newNbrArticles = item.nbrArticles;
            return foundArticle;
          })
          .filter((article) => !!article) as Article[]);
  };

  return (
    <>
      {room && activeArticles && (
        <FullScreenPopup
          isActionBarHiddenOnScroll={false}
          actionBarContent={
            <>
              <PleaseFillIn
                show={!!warningText}
                alternateWarning={warningText}
                centerText={true}
              />
              <FullWidthButton
                text={
                  isSelectArticlesMode ? t("continue2String") : t("addString")
                }
                onClick={async () => {
                  const selectedArticleIds = fetchSelectedArticleIdsFunction();
                  if (isSelectArticlesMode) {
                    if (selectedArticleIds.length > 0) {
                      setModalVisibleByType(
                        ModalTypes.SELECTED_ARTICLES_ACTIONS
                      );
                      setSelectedArticles(
                        selectedArticleIdsToArticles(selectedArticleIds)
                      );
                    } else {
                      setWarningText(t("selectArticlesToContinueString"));
                    }
                  } else {
                    setSelectedArticles([]);
                    setModalVisibleByType(ModalTypes.FOOTER_BUTTON_ACTION);
                  }
                }}
                type={"primary"}
              />
            </>
          }
          appBarConfig={{
            title: roomHeader,
            actions: appBarActions,
            onBack: () => {
              push("/roomsettings");
            },
          }}
          noPadding
        >
          <>
            {roomImages && roomImages.length > 0 ? (
              <RoomImagesCarousel room={room} />
            ) : (
              <div style={{ position: "relative", display: "inline-block" }}>
                {roomImages && roomImages.length > 0 && (
                  <div
                    style={{
                      position: "absolute",
                      top: "80%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      zIndex: 1,
                    }}
                  >
                    <SVGResizer>
                      <Image />
                    </SVGResizer>
                    <UploadImageButton
                      roomId={room.id}
                      text={t("addPhotoString")}
                    />
                  </div>
                )}
              </div>
            )}

            {sortedAndFilteredArticles && (
              <>
                <ArticleFilters onArticlesChange={handleSortedArticles} />
                <RoomArticles
                  sortedAndFilteredArticles={sortedAndFilteredArticles}
                  room={room}
                  groups={groups || []}
                  isSelectArticlesMode={isSelectArticlesMode}
                  getSelectedArticleIds={(
                    fetchFunction: () => ArticleIdNbrArticles[]
                  ) => {
                    fetchSelectedArticleIdsFunction = fetchFunction;
                  }}
                  groupMenuClicked={(group: Group) => {
                    setCurrentGroup(group);
                    setModalVisibleByType(ModalTypes.GROUP_ACTIONS);
                  }}
                  setSelectArticleMode={() => {
                    setIsSelectArticlesMode(!isSelectArticlesMode);
                    setWarningText("");
                  }}
                  allArticlesInRoom={activeArticles}
                />
              </>
            )}

            {sortedAndFilteredArticles &&
              sortedAndFilteredArticles.length === 0 && <NoDataMessage />}

            {toastVisible && (
              <SkapaToast
                text={toastText}
                isOpen={toastVisible}
                onCloseRequest={() => {
                  setToastVisible(false);
                }}
              />
            )}
            {modalVisibleByType !== ModalTypes.DEFAULT_VIEW && (
              <RoomArticlesModals
                selectedArticles={selectedArticles}
                allArticles={activeArticles}
                currentModalType={modalVisibleByType}
                onModalTypeChange={(modalType: ModalTypes) => {
                  if (modalType === ModalTypes.DEFAULT_VIEW) {
                    setIsSelectArticlesMode(false);
                  }
                  setWarningText("");
                  setModalVisibleByType(modalType);
                }}
                groups={groups || []}
                storeId={room.store_id}
                roomId={roomId ?? ""}
                currentGroup={currentGroup}
              />
            )}
          </>
        </FullScreenPopup>
      )}
    </>
  );
}
