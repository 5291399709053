import React from "react";
import { ListImage } from "../Atoms/ListImage";
import { ArticleIdentifier } from "../Atoms/ArticleIdentifier";
import { Text } from "../Atoms/Text";
import { RightArrow } from "../Atoms/Icons/RightArrow";
import { ArticleListItemActionRow } from "./ArticleListItemActionRow";
import "../styles/RoomSettings.css";
import {
  CenterContainer,
  LeftContainer,
  RightContainer,
  ListItemBanner,
  ListItemWrapper,
  FlexibleRowContainer,
  MainProductMessage,
  OngoingTaskMessage,
  SalesStopMessage,
  BackupProductMessage,
  EDSMessage,
} from "../styles/styles";
import {
  Article,
  ArticleIdNbrArticles,
  EdsData,
  SalesStopData,
} from "../types/article";
import { formatFullIdWithDots, FixaWindow } from "@coworker/reusable";
import { useWorkspacesAction } from "../../../hooks/useWorkspaces";
import "@ingka/commercial-message/dist/style.css";
import { useTranslation } from "@coworker/locales";
import { updateArticle } from "../services/articles.service";
import { useQueryClient } from "@tanstack/react-query";
import { useCountryId } from "../../../core/auth/useLoggedInUser";
import Checkbox from "@ingka/checkbox";
import { PiecesText } from "../Atoms/PiecesText";
import { PleaseFillIn } from "../../Reusable/PleaseFillIn";
import { useRoomById } from "../hooks/useRoomById";
import { logEvent } from "../../../helpers/tracker";
import { MediumSkeletonListItem } from "./skeletons/MediumSkeletonListItem";
import { formatEDSDate } from "../../Insights/helpers";

interface ArticleListItemProps {
  article?: Article | undefined;
  isMainProduct?: (articleId: string) => boolean;
  isBackupForProduct?: (articleId: string) => boolean;
  hasOngoingTask?: (articleId: string) => boolean;
  showActionRow?: boolean;
  hideChevron?: boolean;
  edsData?: EdsData | null;
  salesStopData?: SalesStopData | null;
  selectArticleIdsMode?: boolean;
  isSelected?: boolean;
  onSelectArticleIdNbrArticles?: (
    articleIdNbrArticles: ArticleIdNbrArticles
  ) => void;
  onArticleUpdate?: (article: Article, isSkeleton: boolean) => void;
}

export function ArticleListItem({
  article,
  isMainProduct,
  isBackupForProduct,
  hasOngoingTask,
  showActionRow,
  hideChevron,
  edsData,
  salesStopData,
  selectArticleIdsMode,
  isSelected,
  onSelectArticleIdNbrArticles,
  onArticleUpdate,
}: ArticleListItemProps) {
  const { push } = useWorkspacesAction();
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const countryId = useCountryId();
  const { data: room } = useRoomById(article?.roomId || "");

  const [bottomAlertMessage, setBottomAlertMessage] =
    React.useState<string>("");
  const [currentNbrArticles, setCurrentNbrArticles] = React.useState<number>(
    article?.nbrArticles || 0
  );
  const [suppressItemClick, setSuppressItemClick] =
    React.useState<boolean>(false);
  const showEDSdate = (window as FixaWindow).showEDSdate;

  const hasBanner = () => {
    if (!article) return false;
    if (isMainProduct && isMainProduct(article.id)) return true;
    if (isBackupForProduct && isBackupForProduct(article.id)) return true;
    if (hasOngoingTask && hasOngoingTask(article.id)) return true;
    if (edsData) return true;
    if (salesStopData) return true;
    return false;
  };

  const handleQuantityChange = async (value: number) => {
    logEvent("ce:MM_articles_quantity_changed");
    if (selectArticleIdsMode && article && currentNbrArticles !== value) {
      setCurrentNbrArticles(value);
      if (value > article.nbrArticles) {
        setBottomAlertMessage(
          t("moveExpandedArticlesInfoString", { newCount: value })
        );
      } else if (value < article.nbrArticles) {
        setBottomAlertMessage(
          t("moveReducedArticlesInfoString", {
            moveCount: value,
            remainingCount: article.nbrArticles - value,
          })
        );
      } else {
        setBottomAlertMessage("");
      }
      if (!!onSelectArticleIdNbrArticles) {
        onSelectArticleIdNbrArticles({
          articleId: article.id,
          nbrArticles: value,
        });
      }
      return;
    }

    if (!article || article.nbrArticles === value) return;

    try {
      await updateArticle(article.id, {
        ...article, //Roomsettings-service expects a full article object when updating
        nbrArticles: value,
      });
      await queryClient.invalidateQueries(["articlesByRoom"]);
    } catch (_error) {
      alert(t("searchErrorString"));
    }
  };

  if (!article) return <></>;

  const isActionRowVisible =
    selectArticleIdsMode && !isSelected ? false : showActionRow;

  const getBadgeColourForEDSDate = (edsDate: string) => {
    const validToDate = new Date(edsDate);
    // Get the current date and time
    const today = new Date();
    // Calculate the date 2 weeks (14 days) from today
    const twoWeeksFromToday = new Date();
    twoWeeksFromToday.setDate(today.getDate() + 14);
    return validToDate < twoWeeksFromToday ? "EDSwarning" : "EDSregular";
  };

  const innerComponent = (
    <>
      <ListItemBanner>
        {isMainProduct && isMainProduct(article.id) && (
          <MainProductMessage message={t("mainProductString")} />
        )}
        {isBackupForProduct && isBackupForProduct(article.id) && (
          <BackupProductMessage
            message={t("backupProductString").toUpperCase()}
          />
        )}
        {hasOngoingTask && hasOngoingTask(article.id) && (
          <OngoingTaskMessage
            message={(t("ongoingTasksString") as string).toUpperCase()}
          />
        )}
        {showEDSdate && edsData && edsData.validToDateTime && (
          <EDSMessage
            className={getBadgeColourForEDSDate(edsData.validToDateTime)}
            message={`${
              new Date(edsData.validToDateTime) > new Date() ? "EDS" : "PEDS"
            } ${formatEDSDate(edsData.validToDateTime, countryId)}`}
          />
        )}
        {salesStopData && (
          <SalesStopMessage
            message={`${(t("salesStopString") as string).toUpperCase()}`}
          />
        )}
      </ListItemBanner>

      <FlexibleRowContainer>
        <LeftContainer>
          <ListImage src={article.imageSmall} alt={""} />
        </LeftContainer>
        <CenterContainer $centerText={false}>
          <Text text={article.itemName} bold />
          <ArticleIdentifier
            articleId={formatFullIdWithDots(article.productArticleId)}
          />
          <Text text={article.itemType} grey />
          {!showActionRow && article.nbrArticles > 0 && (
            <PiecesText pieces={article.nbrArticles} />
          )}
        </CenterContainer>
        <RightContainer>
          {selectArticleIdsMode ? (
            <Checkbox
              id={article.id}
              value={article.id}
              checked={!!isSelected}
              onChange={() => {
                if (onSelectArticleIdNbrArticles) {
                  onSelectArticleIdNbrArticles({
                    articleId: article.id,
                    nbrArticles: currentNbrArticles,
                  });
                }
              }}
            />
          ) : (
            <>{!hideChevron && <RightArrow />}</>
          )}
        </RightContainer>
      </FlexibleRowContainer>
      {isActionRowVisible && (
        <ArticleListItemActionRow
          ariaDescribedBy="articleQuantityStepper"
          ariaDescribedById="articleQuantityStepper"
          minVal={1}
          maxVal={99}
          defaultValue={article?.nbrArticles || 0}
          onQuantityChange={(value) => handleQuantityChange(value)}
          stopPropagation={(doStop: boolean) => {
            setSuppressItemClick(doStop);
          }}
          article={article}
          onArticleUpdate={(newArticle, isSkeleton) => {
            onArticleUpdate && onArticleUpdate(newArticle, isSkeleton);
          }}
          selectArticleIdsMode={!!selectArticleIdsMode}
          room={room}
        />
      )}
      {!!bottomAlertMessage && isSelected && (
        <div
          style={{
            display: "flex",
            gap: "16px",
            marginTop: "8px",
            marginLeft: "68px",
          }}
        >
          <PleaseFillIn
            show={!!bottomAlertMessage}
            alternateWarning={bottomAlertMessage}
          />
        </div>
      )}
    </>
  );

  return (
    <ListItemWrapper
      $hasBanner={hasBanner()}
      onClick={() => {
        if (hideChevron || selectArticleIdsMode || suppressItemClick) {
          setSuppressItemClick(false);
          return;
        }
        push(`/roomsettings/articles/${article.id}`);
      }}
    >
      {article.isSkeleton && <MediumSkeletonListItem />}
      {!article.isSkeleton && innerComponent}
    </ListItemWrapper>
  );
}
