import React from "react";
import ListView from "@ingka/list-view";
import { AlertResponse, Room } from "../types/room";
import { TaskLinks } from "../types/taskLink";
import { RoomsViewSearchListItemSkeleton } from "../Molecules/RoomsViewSearchListItemSkeleton";
import { RoomsViewRoomsList } from "./RoomsViewRoomsList";
import { SearchResultCountText } from "../Atoms/SearchResultCountText";
import { RoomsViewNoSearchResult } from "../Atoms/RoomsViewNoSearchResult";
import { useMatchingRooms } from "../../../hooks/useMatchingRooms";
import { useSearchArticlesInRoom } from "../hooks/useSearchArticlesInRooms";
import { RoomsViewArticleList } from "./RoomsViewArticleList";
import { GreyRow } from "../styles/styles";
import Text from "@ingka/text";
import { Trans } from "@coworker/locales";

interface RoomsViewSearchListProps {
  activeRooms: Room[] | undefined;
  query: string;
  taskLinks: TaskLinks;
  alerts: AlertResponse[] | undefined;
  storeId: string;
}

export function RoomsViewSearchList({
  activeRooms,
  query,
  taskLinks,
  alerts,
  storeId,
}: RoomsViewSearchListProps) {
  const rooms = useMatchingRooms(activeRooms, query);
  const { data: articles, isLoading } = useSearchArticlesInRoom(storeId, query);

  if (isLoading) {
    return (
      <ListView id="rooms-list">
        {[1, 2, 3, 4, 5, 6].map((index) => (
          <RoomsViewSearchListItemSkeleton key={index} />
        ))}
      </ListView>
    );
  }
  if ((rooms && rooms.length) || (articles && articles.length)) {
    return (
      <>
        <SearchResultCountText
          count={(rooms?.length ?? 0) + (articles?.length ?? 0)}
        />
        {rooms && rooms.length !== 0 && (
          <>
            <GreyRow>
              <Text>
                <b>
                  <Trans count={rooms?.length ?? 0}>
                    countRoomSettingsString
                  </Trans>
                </b>
              </Text>
            </GreyRow>
            <RoomsViewRoomsList
              activeRooms={rooms}
              taskLinks={taskLinks}
              alerts={alerts}
            />
          </>
        )}
        {articles && articles.length !== 0 && (
          <>
            <GreyRow>
              <Text>
                <b>
                  <Trans count={articles?.length ?? 0}>
                    countArticlesString
                  </Trans>
                </b>
              </Text>
            </GreyRow>
            <RoomsViewArticleList
              articles={articles}
              taskLinks={taskLinks}
              storeId={storeId}
            />
          </>
        )}
      </>
    );
  } else {
    return <RoomsViewNoSearchResult query={query ?? ""} />;
  }
}
