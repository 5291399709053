import React from "react";
import { ArticleListItem } from "../Molecules/ArticleListItem";
import { useArticleById } from "../hooks/useArticleById";
import { useRoomById } from "../hooks/useRoomById";
import { useParams } from "react-router";
import { useTranslation } from "@coworker/locales";
import { RoomSettingsPlacement } from "../Molecules/RoomSettingsPlacement";
import { SupplierNumber } from "../Molecules/SupplierNumber";
import { DateStamp } from "../Molecules/DateStamp";
import { useQueryClient } from "@tanstack/react-query";
import { GreyStrip } from "../Atoms/GreyStrip";
import { CreateTasks } from "../Organisms/CreateTasks";
import { useTasksByArticle } from "../hooks/useTasksByArticle";
import { useRoomsAndArticleCount } from "../hooks/useRoomsAndArticleCount";
import {
  getPrimeTasks,
  getSortedRoomsArticleCount,
} from "./ArticleView.helper";
import { ArticleViewSkeleton } from "../Skeletons/ArticleViewSkeleton";
import {
  isBackupForProduct,
  isMainArticle,
} from "../Molecules/RoomArticles.helper";
import FullScreenPopup from "@coworker/apprestructured/src/layout/components/FullScreenPopup/FullScreenPopup";
import { ArticleStock } from "../Molecules/ArticleStock";
import ListView from "@ingka/list-view";
import { HFBLocation } from "../Molecules/HFBLocation";
import { useWorkspacesAction } from "../../../hooks/useWorkspaces";

export function ArticleView() {
  const queryClient = useQueryClient();
  const { articleId } = useParams();
  const { t } = useTranslation();
  const { push } = useWorkspacesAction();

  const {
    data: article,
    refetch,
    isLoading: articleLoading,
  } = useArticleById(articleId);
  const { data: room, isLoading: roomLoading } = useRoomById(article?.roomId);
  const { tasks } = useTasksByArticle(
    room?.store_id,
    article?.productArticleId
  );
  const { data: roomsArticleCount, isLoading: racLoading } =
    useRoomsAndArticleCount(article?.productArticleId, room?.store_id);

  const sortedRoomsArticleCount = getSortedRoomsArticleCount(
    room?.name ?? "",
    roomsArticleCount || [],
    article?.id
  );
  const primeTasks = getPrimeTasks(roomsArticleCount || [], tasks);

  const header = `${article?.itemName} (${room?.name})`;

  const handleUpdateArticle = async () => {
    await queryClient.invalidateQueries(["article", article?.id]);
    await refetch();
  };

  if (articleLoading || roomLoading || racLoading)
    return (
      <FullScreenPopup
        appBarConfig={{
          title: t("loadingString"),
          actions: [],
          onBack: () => {
            push("/roomsettings/" + room?.id);
          },
        }}
        noPadding
      >
        <ArticleViewSkeleton />
      </FullScreenPopup>
    );

  return (
    <>
      {article && room && tasks && (
        <FullScreenPopup
          appBarConfig={{
            title: header,
            actions: [],
            onBack: () => {
              push("/roomsettings/" + room?.id);
            },
          }}
          noPadding
        >
          <ArticleListItem
            article={article}
            isMainProduct={() =>
              isMainArticle(
                article.id,
                room.main_article_id_1,
                room.main_article_id_2
              )
            }
            isBackupForProduct={() =>
              isBackupForProduct(
                article.id,
                room.backup_article_id_1 ?? "",
                room.backup_article_id_2 ?? ""
              )
            }
            showActionRow={true}
            hideChevron={true}
          />
          <ListView id="article-view-list" size={"small"}>
            <RoomSettingsPlacement
              roomsArticleCount={sortedRoomsArticleCount || []}
              currentArticleId={article.id}
            />

            <HFBLocation hfb={article.hfb} />

            <ArticleStock
              productArticleId={article.productArticleId}
              productArticleType={article.productArticleType}
            />

            <SupplierNumber
              article={article}
              value={article.supplierNumber ?? ""}
              onUpdateArticle={handleUpdateArticle}
            />

            <DateStamp
              article={article}
              value={article.dateStamp ?? ""}
              onUpdateArticle={handleUpdateArticle}
            />
          </ListView>

          <GreyStrip text={t("foundInAllRoomSettingsString")} />

          <CreateTasks
            roomsArticleCount={sortedRoomsArticleCount || []}
            tasks={primeTasks}
            currentArticleId={article.id}
          />
        </FullScreenPopup>
      )}
    </>
  );
}
