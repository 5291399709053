import { firebase } from "@coworker/common/config/firebase";
import { msalPublicClientApplication as msalInstance } from "../../core/auth/useAuth";
import { tokenScopes } from "../../core/auth/msalConfig";
import { AccountInfo } from "@azure/msal-browser";

const getFirebaseToken = async () => {
  const token = await firebase.auth().currentUser?.getIdToken();
  if (!token) throw new Error("No user found");
  return token;
};

const getMsalToken = async () => {
  const accounts = msalInstance.getAllAccounts();
  if (!accounts[0]) {
    console.log("user is not signed in");
    /*
     * User is not signed in. Throw error or wait for user to login.
     * Do not attempt to log a user in outside of the context of MsalProvider
     */
  }
  try {
    console.log("Acquiring token silently");
    const response = await msalInstance.acquireTokenSilent({
      scopes: tokenScopes,
      account: accounts[0] as AccountInfo,
    });
    return response.accessToken;
  } catch (error: unknown) {
    throw new Error(`Failed to fetch silent Token: ${error}`);
  }
};

export async function getTokenAsync(isServiceCall: boolean) {
  if (isServiceCall) {
    return await getMsalToken();
  }
  return await getFirebaseToken();
}
