import React, { useRef, useState, useMemo, useEffect } from "react";
import { Trans } from "@coworker/locales";
import styles from "./task-list.module.css";
import { SwipeableTaskCard } from "../TaskCard/SwipeableTaskCard";
import { ProductHeader } from "@coworker/app/src/components/ProductHeader";
import { createTaskGroupList } from "@coworker/app/src/helpers/taskGrouping";
import { GroupedTasksHeader } from "../GroupedTasksHeader/GroupedTasksHeader";
import { PaginationControls } from "../PaginationControls/PaginationControls";
import { CardModeSwitcher } from "../TaskCard/CardModeSwitcher";
import { CardMode } from "../TaskCard/types";

interface TaskListProps {
  tasks: any[];
  totalTasksCount: number;
  isMyTasks: boolean;
  multiSelect: boolean;
  type: string;
  groupBy: string;
  addonFilterOption: string[];
}

const TASKS_PER_PAGE = 20;

const TaskList = ({
  tasks,
  totalTasksCount,
  isMyTasks,
  multiSelect,
  type,
  groupBy,
  addonFilterOption,
}: TaskListProps) => {
  const addonFilter = addonFilterOption?.[0];
  const items: any[] = useMemo(
    () => createTaskGroupList(tasks, groupBy, addonFilter),
    [groupBy, tasks, addonFilter]
  );
  const pageNumber = parseInt(
    localStorage.getItem(`${type}TasksPageNumber`) ?? "1"
  );

  const [currentPageIndex, setCurrentPageIndex] = useState(
    pageNumber > Math.ceil(items.length / TASKS_PER_PAGE)
      ? Math.ceil(items.length / TASKS_PER_PAGE)
      : pageNumber
  );
  const cardModeValue = localStorage.getItem("cardMode") ?? "default";
  const [cardMode, setCardMode] = useState(cardModeValue as CardMode);

  const tasksListRef = useRef<HTMLDivElement>(null);
  const tasksLength = tasks.filter((task) => !task.isProductHeader).length;

  const currentPageOfTasks = items.slice(
    (currentPageIndex - 1) * TASKS_PER_PAGE,
    currentPageIndex * TASKS_PER_PAGE
  );

  useEffect(() => {
    if (currentPageIndex > Math.ceil(items.length / TASKS_PER_PAGE)) {
      const lastPage = Math.ceil(items.length / TASKS_PER_PAGE);
      setCurrentPageIndex(lastPage);
      localStorage.setItem(`${type}TasksPageNumber`, lastPage.toString());
    }
  }, [items.length, currentPageIndex, type]);

  return (
    <div className={styles["task-list"]}>
      <div className={styles["counter"]}>
        <Trans>viewingString</Trans>
        {` ${tasksLength}/${totalTasksCount} `}
        <Trans>TasksString</Trans>
        <CardModeSwitcher cardMode={cardMode} setCardMode={setCardMode} />
      </div>
      <div className={styles["tasks"]} ref={tasksListRef}>
        {/* display 20 per page */}
        {currentPageOfTasks.map((item, i) => {
          if (item.isProductHeader) {
            return (
              <ProductHeader
                key={`related${item.fullId}`}
                fullId={item.fullId}
              />
            );
          } else if (item.isGroupHeader) {
            return (
              <GroupedTasksHeader
                key={i}
                selectedGroupByOption={item.groupByOption}
                numberOfTasksInGroup={item.numberOfTaskInGroup}
                groupByTitle={item.groupByTitle}
              />
            );
          } else {
            return (
              <SwipeableTaskCard
                key={item.id + item.group}
                task={item}
                multiSelect={multiSelect}
                isMyTasks={isMyTasks}
                type={type}
                cardMode={cardMode}
              />
            );
          }
        })}
      </div>
      {items.length > TASKS_PER_PAGE && (
        <PaginationControls
          currentPage={currentPageIndex}
          setCurrentPage={(page) => {
            setCurrentPageIndex(page);
            localStorage.setItem(`${type}TasksPageNumber`, page.toString());
            // scroll to bottom of the list when changing page
            tasksListRef.current?.scrollIntoView({
              behavior: "smooth",
              block: "end",
            });
          }}
          pageSize={TASKS_PER_PAGE}
          totalItems={items.length}
        />
      )}
    </div>
  );
};

export default TaskList;
